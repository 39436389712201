import { Cover, Inline } from '@bedrock-layout/primitives'
import tw, { css } from 'twin.macro'
import type { TFullWidthTextSection } from './interface'
import {
  backgroundPatternStyles,
  getBgPattern,
  getBgPatternPosition,
  getContentPosition,
  getXPosition,
  getYPosition,
} from './styles'

const FullWidthTextSection = (props: TFullWidthTextSection) => {
  const { colorTheme, minHeight, children, xAlign, yAlign, bgPattern, patternPosition } = props

  return (
    <Cover
      minHeight={minHeight}
      css={[
        css`
          background-color: ${colorTheme};
          ${tw`relative overflow-hidden`}
        `,
        backgroundPatternStyles,
        getContentPosition(yAlign),
        bgPattern && getBgPattern(bgPattern),
        patternPosition && getBgPatternPosition(patternPosition),
      ]}
    >
      <Inline
        css={[
          tw`flex flex-col h-full py-12 px-6 md:px-10 xl:px-14 pt-40 xl:pt-[12.5rem] w-full mx-auto max-w-[1920px]`,
          getXPosition(xAlign),
          getYPosition(yAlign),
        ]}
      >
        <div css={[tw`w-full md:w-3/4 lg:w-1/2`, xAlign === 'center' && tw`text-center`]}>{children}</div>
      </Inline>
    </Cover>
  )
}

export default FullWidthTextSection
