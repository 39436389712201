import tw, { css } from 'twin.macro'
import { AnglesCSS } from '../illustrations/Angles'
import { ArchCSS } from '../illustrations/Arch'
import { BranchesCSS } from '../illustrations/Branches'
import { GearCSS } from '../illustrations/Gear'
import { LaurelCSS } from '../illustrations/Laurel'
import type { TPatternPosition, TPatternVariants, TXPosition, TYPosition } from './interface'

const xPosition = {
  left: tw`items-start`,
  center: tw`items-center`,
  right: tw`items-end`,
}

const yPosition = {
  top: tw`justify-start`,
  center: tw`justify-center`,
  bottom: tw`justify-end`,
}

export const getXPosition = (position: TXPosition) => {
  return xPosition[position]
}

export const getYPosition = (position: TYPosition) => {
  return yPosition[position]
}

export const backgroundPatternStyles = css`
  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    ${tw`mr-[10px] w-[40%] bg-cover bg-center lg:w-[50%] lg:mr-[32px] lg:bg-contain lg:bg-right`}
  }
`

const bgPatterns = {
  branches: css`
    &::before {
      ${BranchesCSS}
    }
  `,
  laurel: css`
    &::before {
      ${LaurelCSS}
    }
  `,
  angles: css`
    &::before {
      ${AnglesCSS}
    }
  `,
  gear: css`
    &::before {
      ${GearCSS}
    }
  `,
  arch: css`
    &::before {
      ${ArchCSS}
    }
  `,
  none: '',
}

const bgPatternPositions = {
  left: css`
    &::before {
      ${tw`left-0`}
      transform: rotateY(180deg);
    }
  `,
  right: css`
    &::before {
      ${tw`right-0`}
    }
  `,
}

export const getBgPattern = (pattern: TPatternVariants) => {
  return bgPatterns[pattern]
}

export const getBgPatternPosition = (position: TPatternPosition) => {
  return bgPatternPositions[position]
}

const contentPosition = {
  top: css`
    [data-bedrock-cover-centered] {
      margin-block-start: initial !important;
    }
  `,
  center: css`
    [data-bedrock-cover-centered] {
      margin-block-start: auto;
      margin-block-end: auto;
    }
  `,
  bottom: css`
    [data-bedrock-cover-centered] {
      margin-block-end: initial !important;
    }
  `,
}

export const getContentPosition = (position: TYPosition) => {
  return contentPosition[position]
}
